export const getFileExt = (filename) => {
  /*
   * Utility function for extracting and normalizing a filename extension.
   */
  const tokens = filename.split('.');
  return tokens[tokens.length - 1].toLowerCase();
};

export const makeImage = (src, cls, noLoop) => {
  /*
   * Converts from URL (src) to HTML based on the file type,
   * to then be added to the image rotation.
   */
  const extension = getFileExt(src);

  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif': {
      const img = document.createElement('img');
      img.src = src;
      img.className = `browsersource-image ${cls}`;
      return img;
    }
    case 'mp4':
    case 'webm': {
      const tempWrapper = document.createElement('div');
      const videoString = `<video
      src="${src}"
      class="${cls}"
      autoplay
      ${noLoop ? '' : 'loop '}
      muted
      >
      </video>`;
      tempWrapper.innerHTML = videoString;
      return tempWrapper.firstChild;
    }
    default:
      return null;
  }
};

export class ImageRotation {
  /*
   * This class handles all the browsersource live graphics rotations,
   * including tracking which image is currently live, what the total set
   * of current images is, and scheduling the rotation to the next image
   *
   * USAGE:
   *    rotation = new ImageRotation()  // creates a rotation. Only needs to be done once
   *    rotation.update(IMAGES)         // pass an array of images from the server to
   *                                       update the images in rotation
   */
  constructor(connection) {
    this.images = [];
    this.currentImageIndex = 0;
    this.currentDuration = 600 * 1000;
    this.connection = connection;
    this.isStreaming = false;
    this.lastRotationTime = null;

    this.render = this.render.bind(this);
    this.recordImpression = this.recordImpression.bind(this);
    this.onStreamStart = this.onStreamStart.bind(this);
    this.onStreamStop = this.onStreamStop.bind(this);

    const searchParams = new URLSearchParams(window.location.search);
    const forceImpressions = searchParams.get('force-impressions');
    this.forceImpressions =
      (forceImpressions && forceImpressions.toLowerCase() === 'true') ||
      FORCE_IMPRESSION_SEND;
  }

  clearTimer() {
    /*
     * clear the previous timer, so we don't have multiple simultaneous rotations
     */
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
      this.updateTimer = null;
    }
  }

  getCurrentImage() {
    return this.images[this.currentImageIndex];
  }

  getPreviousImage() {
    const index = this.currentImageIndex;
    const { length } = this.images;
    return this.images[index !== 0 ? index - 1 : length - 1];
  }

  static getScreenArea() {
    /*
     * Returns the percentage of the canvas covered by the Live Graphic,
     * currently to two decimals places precision
     *
     */
    const screenArea = window.innerWidth * window.innerHeight;
    let imageElement = document.querySelector('.prev');

    if (!imageElement) {
      imageElement = document.querySelector('.current');
    }

    if (!imageElement) {
      return 0;
    }

    const boundingBox = imageElement.getBoundingClientRect();
    const imageArea = boundingBox.width * boundingBox.height;

    return Math.round((imageArea / screenArea) * 10000) / 100;
  }

  recordImpression(image) {
    if (this.isStreaming || this.forceImpressions) {
      const previous = this.lastRotationTime;
      const now = Date.now();

      if (previous) {
        const impressionData = {
          start: previous,
          duration: (now - previous) / 1000,
          coverage: this.constructor.getScreenArea(image, 'prev'),
          version_id: image.version_id,
        };

        this.connection.send({
          action: 'record-lg-impressions',
          value: impressionData,
        });
      }

      this.lastRotationTime = now;
    }
  }

  render(forceUpdate) {
    /*
     * If there is more than one image in the rotation, render the NEXT image in the rotation
     * (and update the local state so that it knows the images have rotated).
     *
     * setTimeout is used recursively to schedule the next rotation, based on the scheduled
     * display time of the current image
     *
     * force_update is used to ensure that images are re-rendered when data is received from
     * the API, even if there are no images left to render (e.g. to removed outdated images)
     */
    if (this.images.length > 1 || forceUpdate) {
      const currentImage = this.getCurrentImage();
      const prevImage = this.getPreviousImage();
      const wrapper = document.getElementById('browsersource-image');

      if (this.images.length > 0) {
        const prev = wrapper.getElementsByClassName('prev');
        for (let i = 0; i < prev.length; i += 1) {
          prev[i].parentNode.removeChild(prev[i]);
        }

        const current = wrapper.getElementsByClassName('current');
        for (let i = 0; i < current.length; i += 1) {
          current[i].classList.add('prev');
          current[i].classList.remove('current');
        }

        const newImage = makeImage(currentImage.image_url, 'current');
        if (newImage) {
          wrapper.appendChild(newImage);
        }

        this.currentDuration = currentImage.duration;
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.images.length;
      } else {
        wrapper.innerHTML = '';
        this.currentImageIndex = 0;
      }

      this.recordImpression(prevImage);
    } else if (this.images.length === 1) {
      this.recordImpression(this.images[0]);
    }

    this.clearTimer();
    this.updateTimer = setTimeout(this.render, this.currentDuration);
  }

  update(rotations) {
    /*
     * Handles new image data coming from the server, including:
     *    - Adding the new images to the loop
     *    - reseting the loop to start with the updated data
     */
    const defaultRotation = rotations.default || {};
    this.images = defaultRotation.images || [];
    this.currentImageIndex = 0;

    this.clearTimer();
    setTimeout(() => this.render(true), 0);
  }

  onStreamStart() {
    if (!this.streaming) {
      this.isStreaming = true;
    }
  }

  onStreamStop() {
    this.isStreaming = false;

    // Send the final impression set (since it will be mid-rotation)
    const prevImage = this.getPreviousImage();
    if (prevImage) {
      this.recordImpression(prevImage);
    }

    // reset previous rotations in case browsersource is not restarted
    // before next stream
    this.lastRotationTime = null;
  }
}
