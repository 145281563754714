export default class FullscreenVideoPlayer {
  /*
   * This class handles playing manually triggered Fullscreen Video ads (aka adds that entirely
   * block out the rest of the stream content)
   *
   * USAGE:
   *   video = new FullscreenVideo()   // instantiates the player. Only needs to be done once
   *   video.play(SRC_STRING)          // pass a string with the url of a video file to play that
   *                                      video (as long as no other video is currently playing)
   */
  constructor(connection) {
    this.videoID = 'fullscreen-video-wrapper';
    this.connection = connection;

    this.cleanup = this.cleanup.bind(this);
    this.onEnded = this.onEnded.bind(this);
  }

  cleanup(error) {
    /*
     * Perform all necessary cleanup after a video finishes or errors out
     */
    delete this.playID;

    // remove video elements
    let videoWrapper = document.getElementById(this.videoID);
    videoWrapper.parentNode.removeChild(videoWrapper);
    videoWrapper = null;

    // log errors, if there were any
    if (LOGGING_ENABLED) {
      if (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }

  render(src) {
    /*
     * Constructs the actual Fullscreen Video HTML.
     * Returns the video object so it can be further manipulated
     */
    const body = document.getElementsByTagName('body')[0];
    const videoWrapper = document.createElement('div');
    videoWrapper.className = 'fullscreen-video-wrapper';
    videoWrapper.id = this.videoID;

    const video = document.createElement('video');
    video.src = src;
    video.className = 'fullscreen-video';
    videoWrapper.appendChild(video);
    body.appendChild(videoWrapper);

    return video;
  }

  onEnded() {
    /*
     * on the video end, we want to record that the video successfully played
     * on the backend, and then cleanup the video
     */
    this.connection.send({
      action: 'record-video-play',
      value: this.playID,
    });
    this.cleanup();
  }

  play({ video_url: videoURL, play_id: playID }) {
    /*
     * MAIN ENTRY point. Pass the URL of a video file to play that video fullscreen
     * (as long as no other video is playgin)
     */
    if (!this.playID) {
      const video = this.render(videoURL);
      video.onended = this.onEnded;

      const videoPromise = video.play();

      videoPromise.then(
        () => { this.playID = playID; },
      ).catch(
        error => this.cleanup(error),
      );
    }
  }
}
