/* eslint-disable class-methods-use-this */
export default class BaseHandler {
  /*
   * Base handler for all streaming software. Centralizes all logic
   * and methods for updating the rest of the browsersource,
   * so each child class should only need to focus on
   */

  constructor(onStreamStart, onStreamStop) {
    this.onStreamStart = onStreamStart;
    this.onStreamStop = onStreamStop;
    this.debugElementId = 'browsersource-debug';

    this.setInititialState = this.setInitialState.bind(this);
    this.setListeners = this.setListeners.bind(this);

    if (SHOW_DEBUG_TEXT) {
      this.createDebugText();
      this.updateDebugText();
    }

    if (this.isInSoftware()) {
      this.setInitialState();
      this.setListeners();
    }
  }

  createDebugText() {
    const p = document.createElement('p');
    p.textContent = 'DEBUGGING ACTIVE';
    p.style.color = '#f00';
    p.style.fontFamily = 'Arial, sans-serif';
    p.style.position = 'absolute';
    p.style.top = '20px';
    p.style.right = '20px';
    p.style.fontSize = '30px';
    p.id = this.DEBUG_ELEMENT_ID;
    document.body.appendChild(p);
  }

  updateDebugText(textString) {
    if (SHOW_DEBUG_TEXT) {
      const p = document.getElementById(this.DEBUG_ELEMENT_ID);
      p.innerHTML = textString;
    }
  }

  isInSoftware() {
    /*
     * Should return `true` if in the browsersource is in this particular software
     * if the browsersource is embedded in that particularly software program,
     * otherwise should return false
     */
    return false;
  }

  setInitialState() {
    /*
     * Detect things like streaming status onLoad, and handle accordingly
     */
  }

  setListeners() {
    /*
     * Sets event listeners, callbacks, and anything else
     * needed to detect relevant software event changes, e.g.
     * the scene switch or streaming status changed.
     */
  }
}
