/* eslint-disable class-methods-use-this */
import BaseHandler from './base';

export default class OBSHandler extends BaseHandler {
  isInSoftware() {
    return !!window.obsstudio;
  }

  setInitialState() {
    this.isStreaming = false;
    this.isSceneVisible = document.visibilityState === 'visible';
    this.currentSceneName = '';

    window.obsstudio.getStatus((status) => {
      if (status === null) {
        // Hack to fix SLOBS issue: in SLOBS we can't detect streaming status
        // so we just set streaming to true and always send data
        this.isStreaming = true;
      } else {
        this.isStreaming = status.streaming;
      }

      this.isStreaming ? this.onStreamStart() : this.onStreamStop();
      this.showCurrentStatus();
    });

    window.obsstudio.getCurrentScene((scene) => {
      if (scene) {
        this.currentSceneName = scene.name;
        this.showCurrentStatus();
      }
    });
  }

  showCurrentStatus() {
    const debugText = `
      streaming: ${this.isStreaming}<br/>
      current scene: ${this.currentSceneName}<br />
      scene visible: ${this.isSceneVisible}
    `;

    this.updateDebugText(debugText);
  }

  setListeners() {
    window.addEventListener(
      'obsSourceVisibleChanged',
      this.handleOBSEvent((e) => {
        this.isSceneVisible = e.detail.visible;
        if (this.isStreaming) {
          this.isSceneVisible ? this.onStreamStart() : this.onStreamStop();
        }
      }),
    );

    window.addEventListener(
      'obsStreamingStarted',
      this.handleOBSEvent(() => {
        this.isStreaming = true;
        this.onStreamStart();
      }),
    );

    window.addEventListener(
      'obsStreamingStopped',
      this.handleOBSEvent(() => {
        this.isStreaming = false;
        this.onStreamStop();
      }),
    );

    window.addEventListener(
      'obsSceneChanged',
      this.handleOBSEvent((event) => {
        this.currentSceneName = event.detail.name;
      }),
    );
  }

  handleOBSEvent(callback) {
    return (e) => {
      callback(e);
      this.showCurrentStatus();
    };
  }
}
