import Connection from './connection';
import FullscreenVideoPlayer from './fullscreen_video';
import DCTAManager from './dctas';
import TestPattern from './test_pattern';
import WidgetManager from './widgets';
import OBSHandler from './detectors/obs';
import { ImageRotation } from './live_graphics';

export default class Browsersource {
  constructor(url) {
    this.url = url;
    this.onMessage = this.onMessage.bind(this);
    this.onStreamStart = this.onStreamStart.bind(this);
    this.onStreamStop = this.onStreamStop.bind(this);

    this.connection = new Connection(this.url, this.onMessage);

    this.rotation = new ImageRotation(this.connection);
    this.videoPlayer = new FullscreenVideoPlayer(this.connection);
    this.testPattern = new TestPattern();
    this.widgets = new WidgetManager();

    this.obsHandler = new OBSHandler(this.onStreamStart, this.onStreamStop);
  }

  onMessage(e) {
    /*
     * All incoming data  from the websocket will be handled by this function.
     * Incoming data should be of the form of:
     *    {
     *      action: ACTION_TYPE,
     *      value: { ACTION_VALUES },
     *    }
     * where `action` is a string that determines what KIND of update to the browsersource
     * is required, and where `value` holds the associated data for that action
     */
    const data = JSON.parse(e.data);

    if (LOGGING_ENABLED) {
      // eslint-disable-next-line no-console
      console.log(data);
    }

    switch (data.action) {
      case 'update-live-graphics':
        this.rotation.update(data.value);
        break;
      case 'trigger-fullscreen-video':
        this.videoPlayer.play(data.value);
        break;
      case 'update-all-dctas':
        // Legacy DCTA: mostly unused, but kept for compatibility
        DCTAManager.updateAll(data.value);
        break;
      case 'update-single-dcta':
        // Legacy DCTA: mostly unused, but kept for compatibility
        DCTAManager.updateDCTA(data.value);
        break;
      case 'force-refresh':
        window.location.reload(true);
        break;
      case 'flash-test-pattern':
        this.testPattern.flash();
        break;
      case 'toggle-test-pattern':
        this.testPattern.toggle();
        break;
      case 'update-widgets':
        this.widgets.update(data.value);
        break;
      default:
        if (LOGGING_ENABLED) {
          // eslint-disable-next-line no-console
          console.log('Invalid browsersource data type');
        }
    }
  }

  onStreamStart() {
    /*
     * Should handle all instances of when a browsersource detects
     * a live stream, including:
     *  - the browsersource is open, and the broadcaster begins streaming
     *  - the stream is live, and the broadcaster switches to a scene
     *    that inclues the browersource
     */
    this.rotation.onStreamStart();
  }

  onStreamStop() {
    /*
     * Should handle all instances of when a browsersource detects
     * it is not longer in a live streamk, including:
     *  - the browsersource is open, and the broadcaster stops streaming
     *  - the stream is live, and the broadcaster switches to a scene
     *    that inclues the browersource
     *
     * Note that all functions called here should be idempotent, as we cannot
     * guarantee this will only be fired once
     */
    this.rotation.onStreamStop();
  }

  run() {
    this.connection.start();
  }
}
